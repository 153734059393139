.Home {
    background: #01252C;
    height: 75vh;
    color: white;
}
.App-logo {
    height: 75vh;
}

p {
  font-size: 1.55em;
}
